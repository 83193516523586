<template>
	<div>
		<v-toolbar elevation="1">
			<v-toolbar-title v-if="this.survey.coachee">Interview met {{ this.survey.coachee.name }}
			</v-toolbar-title>
			<v-btn icon @click="refreshView"><v-icon>mdi-refresh</v-icon></v-btn>
			<v-spacer />

			<div v-show="userInfo.role != 'coachee'">
				<pdfButton :data="{ survey, questions, surveyId }" />
				<AddComment :survey="survey" :surveyId="surveyId" />
			</div>
			<v-spacer />

			<v-icon @click="$router.go(-1)">mdi-close-circle</v-icon>
			<v-dialog width="1024" max-width="100vw" v-model="showDialog">
				<ResultCard :key="dialogKey" :question="question" :surveyId="surveyId" v-on:closeDialog="showDialog = false"
					v-on:refreshView="refreshView" />
			</v-dialog>
		</v-toolbar>
		<v-container>
			<v-row class="align-center" :style="stickyTop">
				<v-col cols="11">
					<v-row>
						<v-col cols="4" class="green lighten-2 white--text" align="center">
							Geeft energie
						</v-col>
						<v-col cols="4" class="yellow lighten-2" align="center">
							Neutraal
						</v-col>
						<v-col cols="4" class="red lighten-2 white--text" align="center">
							Kost energie
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="1" />
			</v-row>
			<v-row align="center" class="green lighten-2">
				<v-col cols="11">
					<!-- grid 1,2,3 -->
					<v-row class="white">
						<v-col cols="4">
							<v-card flat outlined v-for="q in grid(1)" :key="q.position" @click="showPopup(q)">
								<v-card-title class="subtitle-1" :class="calculateStyle(q.group)">
									{{ q.position }} - {{ q.title }}
								</v-card-title>
							</v-card>
						</v-col>
						<v-col cols="4">
							<v-card v-for="q in grid(2)" :key="q.position" @click="showPopup(q)" cols="3" flat outlined>
								<v-card-title class="subtitle-1" :class="calculateStyle(q.group)">
									{{ q.position }} - {{ q.title }}
								</v-card-title>
							</v-card>
						</v-col>
						<v-col cols="4">
							<v-card v-for="q in grid(3)" :key="q.position" @click="showPopup(q)" cols="3" flat outlined>
								<v-card-title class="subtitle-1" :class="calculateStyle(q.group)">
									{{ q.position }} - {{ q.title }}
								</v-card-title>
							</v-card>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="1" align="center" class="white--text">
					Ontwikkeld</v-col>
			</v-row>
			<v-row><v-divider class="green lighten-2 pt-1" /></v-row>
			<v-row align="center" class="yellow lighten-2">
				<v-col cols="11">
					<!-- grid 4,5,6 -->
					<v-row class="white">
						<v-col cols="4">
							<v-card v-for="q in grid(4)" :key="q.position" @click="showPopup(q)" flat outlined>
								<v-card-title class="subtitle-1" :class="calculateStyle(q.group)">
									{{ q.position }} - {{ q.title }}
								</v-card-title>
							</v-card>
						</v-col>
						<v-col cols="4">
							<v-card v-for="q in grid(5)" :key="q.position" @click="showPopup(q)" cols="3" flat outlined>
								<v-card-title class="subtitle-1" :class="calculateStyle(q.group)">
									{{ q.position }} - {{ q.title }}
								</v-card-title>
							</v-card>
						</v-col>
						<v-col cols="4">
							<v-card v-for="q in grid(6)" :key="q.position" @click="showPopup(q)" cols="3" flat outlined>
								<v-card-title class="subtitle-1" :class="calculateStyle(q.group)">
									{{ q.position }} - {{ q.title }}
								</v-card-title>
							</v-card>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="1" align="center"> Half ontwikkeld</v-col>
			</v-row>
			<v-row><v-divider class="yellow lighten-2 pt-1" /></v-row>
			<v-row align="center" class="red lighten-2">
				<v-col cols="11">
					<!-- grid 31 32 33 -->
					<v-row class="white">
						<v-col cols="4">
							<v-card v-for="q in grid(7)" :key="q.position" @click="showPopup(q)" flat outlined>
								<v-card-title class="subtitle-1" :class="calculateStyle(q.group)">
									{{ q.position }} - {{ q.title }}
								</v-card-title>
							</v-card>
						</v-col>
						<v-col cols="4">
							<v-card v-for="q in grid(8)" :key="q.position" @click="showPopup(q)" flat outlined>
								<v-card-title class="subtitle-1" :class="calculateStyle(q.group)">
									{{ q.position }} - {{ q.title }}
								</v-card-title>
							</v-card>
						</v-col>
						<v-col cols="4">
							<v-card v-for="q in grid(9)" :key="q.position" @click="showPopup(q)" flat outlined>
								<v-card-title class="subtitle-1" :class="calculateStyle(q.group)">
									{{ q.position }} - {{ q.title }}
								</v-card-title>
							</v-card>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="1" align="center" class="white--text">
					Niet ontwikkeld
				</v-col>
			</v-row>
		</v-container>
		<v-divider class="my-8" />
		<v-container>
			<v-row>
				<v-col cols="12">
					<v-data-table :items="tableView" :headers="headers" :disable-sort="this.$vuetify.breakpoint.mobile"
						:loading="loading" item-key="id" no-data-text="" no-results-text="">

						<template v-slot:[`item.title`]="{ item }">

							<div :class="calculateStyle(item.group)">
								<!-- <pre>GROUP: {{ item.group }}  COLOR: {{ calculateStyle(item.group) }}</pre> -->
								<v-btn plain color="primary" @click="showPopup(questions[item.position - 1])">{{ item.title
								}}</v-btn>
							</div>

						</template>

						<template v-slot:[`item.preference`]="{ item }">
							<v-rating v-model="item.preference" empty-icon="mdi-circle-outline" full-icon="mdi-circle"
								half-icon="mdi-circle-half" half-increments density="compact" size="small" color="black"
								readonly></v-rating>
						</template>

						<template v-slot:[`item.latency`]="{ item }">
							<div class="text-center">
								<v-icon :color="latencyColor(item.latency)">{{ latencyIcon(item.latency) }}</v-icon>
							</div>
						</template>

						<template v-for="i in peers.length" v-slot:[`item.peer${i-1}.latency`]="{ item }">
							<div class="text-center">

								<v-icon :color="latencyColor(getLatency(item, i - 1))">
									{{ latencyIcon(getLatency(item, i - 1)) }}
								</v-icon>

							</div>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import pdfButton from "@/components/pdfButton.vue";
import AddComment from "@/components/AddComment.vue";
import { db } from "@/services/firebase";
import {
	onSnapshot,
	doc,
	getDoc,
	collection,
	getDocs,
	query, orderBy, where
} from "firebase/firestore";
import ResultCard from "@/components/ResultCard";
export default {
	name: "Grid",
	components: { ResultCard, pdfButton, AddComment },

	computed: {
		stickyTop() {
			return {
				position: "sticky",
				top: this.$vuetify.application.top + "px",
				zIndex: 1,
			};
		},
		...mapGetters({
			userInfo: "session/userInfo",
		}),
		headers() {
			// Base headers
			let baseHeaders = [
				{
					text: "Stelling",
					value: "title"
				},
				{
					text: "Voorkeur",
					value: "preference"
				},
				{
					text: "Aanwezigheid",
					value: "latency",
					align: "center"
				}
			];

			// Dynamic peer headers
			let peerHeaders = this.generatePeerHeaders(this.peers.length);

			// Combine the base headers with the dynamic peer headers
			return baseHeaders.concat(peerHeaders);
		}

	},

	data() {
		return {
			loading: false,
			clusters: [],
			survey: {},
			questions: [],
			showDialog: false,
			question: {},
			dialogKey: 1,
			unsub: null,
			surveyId: "",
			peers: [],
			tableView: [],
		};
	},

	created() {

		const subColRef = collection(db, "clusters");
		getDocs(subColRef).then((res) => {
			this.clusters = res.docs.map((doc) => {
				return { ...doc.data(), id: doc.id };
			});
		})


		// watch the params of the route to fetch the data again
		this.$watch(
			() => this.$route.params,
			(_new, _old) => {
				if (
					_new &&
					_new.id &&
					_new != _old &&
					this.$route.name === "Grid"
				) {
					this.surveyId = _new.id;
					this.fetchSurvey(_new.id).then(() => { });
				}
			},
			// fetch the data when the view is created and the data is
			// already being observed
			{ immediate: true }
		);
	},
	methods: {
		getLatency(item, index) {
			const peer = item['peer' + index];
			return peer ? peer.latency : null;
		},
		generatePeerHeaders(length) {
			let headers = [];
			for (let i = 1; i <= length; i++) {
				headers.push({
					text: this.peers[i - 1].firstName,
					value: `peer${i - 1}.latency`,
					align: "center"
				});
			}
			return headers;
		},

		grid(id) {
			return this.questions.filter((q) => {
				return q.outcome.grid === id;
			});
		},

		calculateStyle(group) {
			/*
			switch (group) {
				case "Creatieve kracht":
					return "orange lighten-3";

				case "Mentale kracht":
					return "purple lighten-3";

				case "Prestatie kracht":
					return "blue lighten-3";

				case "Prosociale kracht":
					return "green lighten-3";

				case "Dynamische kracht":
					return "red lighten-2";
			}
*/

			const idx = this.clusters.findIndex(cluster => cluster.name == group)
			if (idx >= 0) {
				return this.clusters[idx].color
			}

		},

		async fetchSurvey(_surveyId) {
			const docRef = doc(db, "surveys", _surveyId);
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {

				// survey
				this.survey = docSnap.data();

				// questions of survey
				const subColRef = collection(
					db,
					"surveys",
					_surveyId,
					"questions"
				);
				const res = await getDocs(subColRef);
				this.questions = res.docs.map((doc) => {
					return { ...doc.data(), id: doc.id };
				});
				this.questions.sort((a, b) => {
					return a.position - b.position;
				});

				// first three peers of survey
				const peersRef = collection(
					db,
					"surveys",
					_surveyId,
					"peers"
				);
				const resp = await getDocs(peersRef);
				this.peers = resp.docs.map((doc) => {
					return { ...doc.data(), id: doc.id };
				});
				this.createTableView()
			}
		},

		createTableView() {
			var _rows = []
			this.questions.forEach(q => {
				var _row = {}
				var _col = 0
				_row.position = q.position
				_row.title = q.title
				_row.preference = q.answer.preference
				_row.latency = q.answer.latency
				this.peers.forEach(p => {

					//_cell.peerId = p.id
					if (p.questions[q.position - 1]) {
						_row[`peer${_col}`] = p.questions[q.position - 1].latency
						_row[`peer${_col}`] = {
							peerId: p.id,
							latency: p.questions[q.position - 1].latency
						}
						_col += 1
					}

				})
				_rows.push(_row)
			})

			this.tableView = _rows
		},

		latencyColor(v) {
			const colors = ["grey", "red", "yellow", "green"]
			return colors[v]
		},

		latencyIcon(v) {
			const icons = ["", "mdi-lightbulb-off-outline", "mdi-lightbulb-on-50", "mdi-lightbulb-on"]
			return icons[v]
		},

		showPopup(q) {
			this.question = q;
			this.dialogKey += 1;
			this.showDialog = true;
			this.unsub = onSnapshot(
				doc(
					db,
					"surveys",
					this.$route.params.id,
					"questions",
					this.question.id
				),
				(doc) => {
					this.refreshView();
					this.unsub = null;
				}
			);
		},

		refreshView() {
			// TBD: wait for database trigger
			this.fetchSurvey(this.$route.params.id);
			this.$forceUpdate();

		},
	},
};
</script>

<template>
    <v-dialog v-model="dialog" width="600" max-width="100vh">
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-information-variant-circle-outline</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                {{ peerAnswer.peer.name }}
                <v-spacer />
                <v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                {{ peerAnswer.context }}
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import { db } from "@/services/firebase";
export default {
    name: "PeerCard",
    props: {
        peerAnswer: Object
    },
    data() {
        return {
            loading: false,
            dialog: false
        };
    },
}
</script>
/* eslint-disable */
<template>
	<v-dialog width="800" max-width="100vw" v-model="dialog">
		<template v-slot:activator="{ on, attrs }">
			<v-btn text outlined v-bind="attrs" v-on="on" class="ml-4">
				Conclusie
			</v-btn>
		</template>

		<v-card>
			<v-card-title
				>Conclusie <v-spacer /><v-icon @click="dialog = false"
					>mdi-close</v-icon
				></v-card-title
			>
			<v-card-text>
				
				Samenvatting
				
				
				<v-textarea
					v-model="survey.conclusion"
					placeholder="conclusie"
				/>

				Opmerking

				<v-textarea
				v-model="survey.opmerking"
					placeholder="opmerking"
					/>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn plain primary @click="addComment()">Bevestigen</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
	import { db } from "@/services/firebase";
	import { doc, updateDoc } from "firebase/firestore";

	export default {
		name: "AddComment",
		props: { survey: Object, surveyId: String },
		data() {
			return {
				dialog: false,
			};
		},
		methods: {
			async addComment() {
				await updateDoc(
					doc(
						db,
						"surveys",

						this.surveyId
					),
					{
						conclusion: this.survey.conclusion,
						opmerking: this.survey.opmerking,
					}
				);
				this.dialog = false;
			},
		},
	};
</script>

<template>
    <v-card outlined>
        <v-card-title>
            Stelling {{ question.position }} : {{ question.title }}
            <v-spacer /><v-btn icon plain large @click="closeDialog()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
            <v-container>
                <v-row>
                    <v-col cols="12" md="6" class="grey lighten-5 text-body-1">
                        {{ question.question }}
                    </v-col>
                    <v-col cols="12" md="5" offset-md="1">
                        <v-row no-gutters>
                            <v-col cols="12"> Mate van energie </v-col>
                            <v-col cols="12">
                                <v-radio-group outlined @change="dataModified = true" v-model="question.answer.preference"
                                    row>
                                    <v-radio v-for="i in 5" :key="i" :value="i" :label="`${i}`"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row><v-col></v-col></v-row>
                        <v-row no-gutters>
                            <v-col cols="12"> Mate van ontwikkeling </v-col>
                            <v-col cols="12">
                                <v-radio-group v-model="question.answer.latency" row @change="dataModified = true">
                                    <v-radio v-for="item in radioItems" :key="item.value" :value="item.value"
                                        :label="item.text"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12"> Mate van zichtbaarheid ( peer review) </v-col>
                        </v-row>
                        <v-row no-gutters align="center" v-for="peerAnswer in peerAnswers">
                            <v-col cols="10">
                                <v-radio-group v-if="peerAnswer" :value="Number(peerAnswer.latency)" row>
                                    <v-radio v-for="item in radioItems" :key="item.value" :value="item.value"
                                        :label="item.text"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="2">
                                <peer-card :peerAnswer="peerAnswer" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-text>
            <v-textarea label="Commentaar" outlined hide-details v-model="question.outcome.comment"
                @input="dataModified = true" />
        </v-card-text>
        <v-card-actions>
            <v-spacer />
            <v-btn v-show="dataModified && userInfo.role != 'coachee'" :loading="loading" large
                class="primary white--text px-10" @click="updateAnswer()">wijzigingen bewaren</v-btn>
            <v-spacer />
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { collection, orderBy, getDocs, updateDoc, doc, query } from "firebase/firestore";
import { db } from "@/services/firebase.js";
import PeerCard from "@/components/PeerCard"

export default {
    name: "ResultCard",
    components: { PeerCard },
    computed: {
        ...mapGetters({
            userInfo: "session/userInfo",
        }),
    },
    props: {
        question: Object,
        surveyId: String,
    },
    created() {
        this.getPeerReviews()
    },
    data() {
        return {
            loading: false,
            updateActive: false,
            dataModified: false,
            radioItems: [
                { text: "Laag", value: 1 },
                { text: "Medium", value: 2 },
                { text: "Hoog", value: 3 },
            ],
            peers: [],
            peerAnswers: []
        };
    },
    methods: {
        async updateAnswer() {
            this.loading = true;
            try {
                await updateDoc(
                    doc(
                        db,
                        "surveys",
                        this.surveyId,
                        "questions",
                        this.question.id
                    ),
                    {
                        answer: {
                            preference: this.question.answer.preference,
                            latency: this.question.answer.latency,
                        },
                        outcome: this.question.outcome,
                    }
                );
                this.dataModified = false;
                this.$emit("refreshView");
                this.$emit("closeDialog");
            } catch (e) {
                alert(e);
            }
            this.loading = false;
        },
        closeDialog() {
            this.$emit("closeDialog");
        },
        async getPeerReviews() {
            const cRef = collection(db, "surveys", this.surveyId, "peers");
            const q = query(cRef, orderBy('sortNr'));
            const res = await getDocs(q);
            this.peers = res.docs.map((doc) => {
                return { ...doc.data(), id: doc.id };
            });

            this.peerAnswers.length = 0
            this.peers.forEach(p => {
                var _idx = p.questions.findIndex(q => q.questionId == this.question.id)
                if (_idx > -1) {
                    this.peerAnswers.push(
                        {
                            peer: {
                                id: p.id,
                                name: p.firstName + ' ' + p.lastName,
                                sortNr: p.sortNr
                            },
                            latency: p.questions[_idx].latency,
                            context: p.questions[_idx].context
                        })

                }
            })
        },

    },
};
</script>

<style></style>